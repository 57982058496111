import Section from "./Section";
import LoginHelpers from "../auth/LoginHelpers";
import ActivityForm from "../forms/ActivityForm";

const Activity = () => {

    if (LoginHelpers.IsTokenValid()) {
        LoginHelpers.RefreshToken();
    }

    return(
        <div>
            <Section label="Submit an Activity" />
            <ActivityForm />
        </div>
    );
};

export default Activity;
