export const ConvertPointsToChartData = items => {
  return (
      items.map(item => (
          {
              "x": item.activityDate,
              "y": item.totalPoints,
          }
      ))
  );
}

export const ConvertToWeightsData = items => {
  return (
      items.map(item => (
          {
              "x": item.CreatedTime,
              "y": item.Weight,
          }
      ))
  );
}

export const ConvertToChestData = items => {
  return (
    items.filter(item => item.Chest).map(item => {
      return {
        "x": item.CreatedTime,
        "y": item.Chest,
      };
    })
  );
};

export const ConvertToWaistData = items => {
  return (
    items.filter(item => item.Waist).map(item => {
      return {
        "x": item.CreatedTime,
        "y": item.Waist,
      };
    })
  );
};

export const ConvertToTorsoData = items => {
  return (
    items.filter(item => item.Torso).map(item => {
      return {
        "x": item.CreatedTime,
        "y": item.Torso,
      };
    })
  );
};