import Section from "./Section";
import WeightForm from "../forms/WeightForm";
import LoginHelpers from "../auth/LoginHelpers";

const Weight = () => {

    if (LoginHelpers.IsTokenValid()) {
        LoginHelpers.RefreshToken();
    }
    
    return(
        <div>
            <Section label="Submit a Weight" />
            <WeightForm />
        </div>
    );
};

export default Weight;
