import { Outlet, Link } from "react-router-dom";

const Nav = () => {
  return (
    <>
      <nav>
        <ul>

          <li>
            <Link to="/home"><i className="fa-solid fa-house"></i></Link>
          </li>

          <li>
            <Link to="/graphs"><i className="fa-solid fa-chart-line"></i></Link>
          </li>
          
          <li>
            <Link to="/weight"><i className="fa-solid fa-weight-scale"></i></Link>
          </li>

          <li>
            <Link to="/activity"><i className="fa-solid fa-person-walking"></i></Link>
          </li>

        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Nav;
