import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Nav from "./pages/Nav";
import Home from "./pages/Home";
import Graphs from "./pages/Graphs";
import Weight from "./pages/Weight";
import Activity from "./pages/Activity";
import NoPage from "./pages/NoPage";

import "./css/index.css";


export default function App() {
    return(
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="graphs" element={<Graphs />} />
            <Route path="weight" element={<Weight />} />
            <Route path="activity" element={<Activity />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
}
