const minDate = new Date();
minDate.setMonth(minDate.getMonth() - 6);

export const PointsChartConfig = {
    chart: {
        type: "bar",
        id: "activities",
        toolbar: {
            show: true,
            tools: {
                zoom: true,
                pan: true,
                reset: true,
                selection: true,
                zoomin: false,
                zoomout: false,
                download: false,
            },
            autoSelected: "zoom",
        },
    },
    plotOptions: {
        bar: {
            borderRadius: 5,
            dataLabels: {
                position: "top",
            },
        },
    },
    xaxis: {
        type: "datetime",
        labels: {
            style: {
                colors: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
            rotate: -15,
            rotateAlways: true,
            datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm'
            }
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            fill: {
                type: 'gradient',
                gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                }
            }
        },
    },
    yaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
        }
    },
    grid: {
        borderColor: '#ccc',
        strokeDashArray: 3,
    },
    dataLabels: {
        enabled: true,
        offsetY: -20,
        formatter: function (val) {
            if (val != 0) { return val; }
        },
        dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
        }
    },
    title: {
        text: 'Total Points',
        floating: true,
        offsetY: 0,
        align: 'center',
        style: {
            color: '#ccc',
            fontFamily: 'Bold-Font, Arial, sans-serif',
        }
    },
    colors: ["#8884d8", "#82ca9d"],
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
        }
    }
}


export const WeightsChartConfig = {
    chart: {
        type: "area",
        id: "weight",
        toolbar: {
            show: true,
            tools: {
                zoom: true,
                pan: true,
                reset: true,
                selection: true,
                zoomin: false,
                zoomout: false,
                download: false,
            },
            autoSelected: "zoom",
        },
        zoom: {
            enabled: true,
            autoScaleYaxis: true,
        }
    },
    stroke: {
        curve: "smooth",
    },
    markers: {
        size: 1,
    },
    tooltip: {
        x: {
            format: 'dd MMM yyyy'
        }
    },
    fill: {
        type: 'gradient',
        colors: ["#82ca9d"],
        gradient: {
            shade: "dark",
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100]
        },
    },
    yaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            style: {
                colors: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
        },
        title: {
            text: "kg",
            style: {
                color: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
        }
    },
    xaxis: {
        type: "datetime",
        tickAmount: 8,
        min: minDate.getTime(),
        labels: {
            style: {
                colors: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
            rotate: -15,
            rotateAlways: true,
            datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm'
            }
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
    },
    grid: {
        borderColor: '#ccc',
        strokeDashArray: 3,
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'Weight',
        floating: true,
        offsetY: 0,
        align: 'center',
        style: {
            color: '#ccc',
            fontFamily: 'Bold-Font, Arial, sans-serif',
        }
    },
    colors: ["#8884d8", "#82ca9d"],
}

export const MeasurementsChartConfig = {
    chart: {
        type: "line",
        id: "measurements",
        toolbar: {
            show: true,
            tools: {
                zoom: true,
                pan: true,
                reset: true,
                selection: true,
                zoomin: false,
                zoomout: false,
                download: false,
            },
            autoSelected: "zoom",
        },
        zoom: {
            enabled: true,
            autoScaleYaxis: true,
        }
    },
    stroke: {
        curve: "smooth",
    },
    markers: {
        size: 0,
    },
    tooltip: {
        x: {
            format: 'dd MMM yyyy'
        }
    },
    yaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            style: {
                colors: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
        },
        title: {
            text: "cm",
            style: {
                color: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
        }
    },
    xaxis: {
        type: "datetime",
        tickAmount: 8,
        min: minDate.getTime(),
        labels: {
            style: {
                colors: "#fff",
                fontFamily: 'Main-Font, Arial, sans-serif',
            },
            rotate: -15,
            rotateAlways: true,
            datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm'
            }
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
    },
    legend: {
        fontFamily: 'Main-Font, Arial, sans-serif',
        floating: false,
        itemMargin: {
            vertical: 10,
            horizontal: 10,
        },
        labels: {
            colors: undefined,
            useSeriesColors: true,
        },
        onItemHover: {
            highlightDataSeries: true
        },
    },
    grid: {
        borderColor: '#ccc',
        strokeDashArray: 3,
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'Measurements',
        floating: true,
        align: 'center',
        style: {
            color: '#ccc',
            fontFamily: 'Bold-Font, Arial, sans-serif',
        }
    },
    colors: ["#8884d8", "#82ca9d", "#db8624"],
}
