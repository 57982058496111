import { useState } from 'react';
import axios from "axios";

export default function WeightForm() {

    const [inputs, setInputs] = useState({});
    const [apiResponse, setApiResponse] = useState({});

    const handleInputChange = (event) => {
        
        const {name, value} = event.target;
        
        setInputs(values => ({...values, [name]: value}))

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Weight submitted: [weight='+inputs.weight+', chest='+inputs.chest+', torso='+inputs.torso+', waist='+inputs.waist+']');

        const requestOptions = {
            crossDomain: true,
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("extrack_token")
            },
        }

        const refreshPayload = {
                Weight: Number(inputs.weight),
                Chest: Number(inputs.chest),
                Torso: Number(inputs.torso),
                Waist: Number(inputs.waist),
        }

        axios.post(process.env.REACT_APP_EXERCISE_TRACKER_API_ENDPOINT + '/weights/', refreshPayload, requestOptions)
            .then((response) => {
                setApiResponse({ status: response.status, payload: response.data })
            })
            .catch(error => {
                console.error('Failed to call weights API to set new weight', error)
                setApiResponse(error)
            })
    }

    const handleApiResponse = (event) => {
        if (event.status === 201) {
            return (<div className="submitted">
                <p>Submitted OK</p>
                <p>Weight {event.payload.Weight}kg<br />Chest {event.payload.Chest}cm<br />Torso {event.payload.Torso}cm<br />Waist {event.payload.Waist}cm</p>
            </div>);
        }
    }

    return (
        <div className="weightForm">
            {handleApiResponse(apiResponse)}
            <form onSubmit={handleSubmit}>
                <label>Weight (kg):</label>
                <input type="text" name="weight" value={inputs.weight || ""} onChange={handleInputChange} />
                <br />
                <label>Chest (cm):</label>
                <input type="text" name="chest" value={inputs.chest || ""} onChange={handleInputChange} />
                <br />
                <label>Torso (cm):</label>
                <input type="text" name="torso" value={inputs.torso || ""} onChange={handleInputChange} />
                <br />
                <label>Waist (cm):</label>
                <input type="text" name="waist" value={inputs.waist || ""} onChange={handleInputChange} />
                <br /><br />
                <input type="submit" value="Save" />
            </form>
        </div>
    )

}
