import { useEffect, useState } from 'react';
import axios from "axios";
import Chart from "react-apexcharts";

import { PointsChartConfig } from "./ChartConfigs";
import { ConvertPointsToChartData } from "./ChartHelpers";

export default function DisplayActivities() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {

    const requestOptions = {
      crossDomain: true,
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem("extrack_token")
      },
    }

    axios.get(process.env.REACT_APP_EXERCISE_TRACKER_API_ENDPOINT + '/points/', requestOptions).then((response) => {
      setIsLoaded(true);
      setItems(response.data);
    }).catch(error => {
      setIsLoaded(true);
      setError(error);
    });

  }, [])

  if (error) {

    if (error.response.status === 401) {
      return <p className="warning">Error: Not authorised - try logging in!</p>;
    } else {
      return <p className="warning">Error: {error.message}</p>;
    }

  } else if (!isLoaded) {

    return <div>Loading ...</div>;

  } else {

    const data = ConvertPointsToChartData(items);
    const options = PointsChartConfig;
    const series = [
      {
        name: "total",
        data: data
      }
    ]

    return (
      <div className="chart">
        <div className="chart-row">
            <Chart
              options={options}
              series={series}
              type="bar"
            />
          </div>
      </div>
    );

  }

}
