import { useEffect, useState } from 'react';
import axios from "axios";
import Chart from "react-apexcharts";

import { WeightsChartConfig, MeasurementsChartConfig } from "./ChartConfigs";
import { ConvertToWeightsData, ConvertToChestData, ConvertToWaistData, ConvertToTorsoData } from "./ChartHelpers";

export default function DisplayWeights() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {

    const requestOptions = {
      crossDomain: true,
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem("extrack_token")
      },
    }

    axios.get(process.env.REACT_APP_EXERCISE_TRACKER_API_ENDPOINT + '/weights/', requestOptions).then((response) => {
      setIsLoaded(true);
      setItems(response.data);
    }).catch(error => {
      setIsLoaded(true);
      setError(error);
    });

  }, []);

  if (error) {

    if (error.response.status === 401) {
      return <p className="warning">Error: Not authorised - try logging in!</p>;
    } else {
      return <p className="warning">Error: {error.message}</p>;
    }

  } else if (!isLoaded) {

    return <div className="message">Loading ...</div>;

  } else {

    const weightData = ConvertToWeightsData(items);
    const weightSeries = [
      {
        name: "Weight",
        data: weightData
      }
    ]

    const chestData = ConvertToChestData(items);
    const waistData = ConvertToWaistData(items);
    const torsoData = ConvertToTorsoData(items);

    const measurementSeries = [
      {
        name: "Chest",
        data: chestData
      },
      {
        name: "Waist",
        data: waistData
      },
      {
        name: "Torso",
        data: torsoData
      },
    ]

    return (
      <div className="chart">

        <div className="chart-row">
            <Chart
              options={WeightsChartConfig}
              series={weightSeries}
              type="area"
            />
          </div>

        <div className="chart-row">
            <Chart
              options={MeasurementsChartConfig}
              series={measurementSeries}
              type="line"
            />
          </div>

      </div>
    );
    
  }


}

