import { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default function ActivityForm() {

    const [inputs, setInputs] = useState({});
    const [apiResponse, setApiResponse] = useState({});
    const [startDate, setStartDate] = useState(new Date());

    const handleInputChange = (event) => {
        
        const {name, value} = event.target;
        
        setInputs(values => ({...values, [name]: value}))

    }

    const handleSubmit = (event) => {
        event.preventDefault()

        const submittedDate = startDate.toISOString()

        console.log('An activity was submitted: ' + JSON.stringify(inputs) + ' for date ' + submittedDate)

        const requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("extrack_token")
            },
        }

        const activitiesPayload = {
            Name: String(inputs.activityName),
            Count: Number(inputs.activityCount),
            Effort: Number(inputs.activityEffort),
            CreatedTime: submittedDate,
        }

        axios.post(process.env.REACT_APP_EXERCISE_TRACKER_API_ENDPOINT + '/activities/', activitiesPayload, requestOptions)
            .then((response) => {
                setApiResponse({ status: response.status, payload: response.data })
            })
            .catch(error => {
                console.error('Failed to call weights API to set new weight', error)
                setApiResponse(error)
            })

    }

    const handleApiResponse = (event) => {
        if (event.status === 201) {
            return (<div className="submitted">
                <p>Submitted OK</p>
                <p>Name: {event.payload.Name}<br />Count: {event.payload.Count}<br />Effort: {event.payload.Effort}
                </p>
            </div>);
        }
    }

    return (
        <div className="activityForm">
            {handleApiResponse(apiResponse)}
            <form onSubmit={handleSubmit}>

                <div className="formRow">
                    <label>Activity:</label>
                    <select name="activityName" value={inputs.activityName} onChange={handleInputChange}>
                        <option value=""></option>
                        <option value="walk">Walk</option>
                        <option value="diy">DIY</option>
                        <option value="play">Exercise with O</option>
                        <option value="grass">Cut Grass</option>
                        <option value="bike">Bike</option>
                        <option value="cross-trainer">Cross-Trainer</option>
                        <option value="punchbag">Punchbag</option>
                        <option value="weights">Weights</option>
                    </select>
                </div>
                
                <div className="formRow">
                    <label>Length / Sets:</label>
                    <input type="text" name="activityCount" onChange={handleInputChange} />
                </div>
                
                <div className="formRow">
                    <label>Effort:</label>
                    <select name="activityEffort" value={inputs.activityEffort} onChange={handleInputChange}>
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                    </select>                
                </div>

                <div className="formRow">
                    <label>Date:</label>
                    <DatePicker
                        wrapperClassName="datePicker"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="E dd/MM/yyyy"
                    />
                </div>

                <div className="formRow">
                    <label>&nbsp;</label>
                    <input type="submit" value="Save" />
                </div>

            </form>
        </div>
    )

}
