import { useState } from 'react';
import { Link } from "react-router-dom";


import LoginHelpers from "../auth/LoginHelpers";

export default function Home() {



    const [userStatus, setUserStatus] = useState({});

    const UserDetails = () => {

        const urlParams = new URLSearchParams(window.location.search);
        const isStatePassed = urlParams.get('state');

        if (!LoginHelpers.IsTokenValid() && (isStatePassed)) {
            generateToken();    // attempted login, generate new token
        }

        if (LoginHelpers.IsTokenValid() && !(isStatePassed)) {
            LoginHelpers.RefreshToken();     // valid token, refresh expiry
        }
        
        const logged_in = window.localStorage.getItem("logged_in");
        const username = window.localStorage.getItem("username");

        if (logged_in) {
            return(<p>{username} logged in. <Logout /></p>);
        } else {
            return(<p>Not logged in. <Login /></p>);
        }
        
    }
    
    const generateToken = () => {

        const requestOptions = {
            crossDomain: true,
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        }

        fetch(process.env.REACT_APP_AUTH_API_ENDPOINT + "/auth/token?" + window.location.search.substr(1), requestOptions)
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            
            if (data.result === true) {

                setUserStatus({ logged_in: "true" })
                
                window.localStorage.setItem('extrack_token', data.refresh_token);
                window.localStorage.setItem('logged_in', true);
                window.localStorage.setItem('username', LoginHelpers.GetUsername());

            }

        })
        .catch(error => {
            console.error('Failed to call auth/token API', error)
        })

    }



    const Login = () => {
        return (<button><a href={process.env.REACT_APP_AUTH_API_ENDPOINT + "/auth/login"}>Login</a></button>)
    }

    const Logout = () => {
        return (<button onClick={handleLogout}>Logout</button>)
    }
    
    const handleLogout = () => {
        window.localStorage.removeItem("extrack_token");
        window.localStorage.removeItem("logged_in");
        window.localStorage.removeItem("username");
        setUserStatus({ logged_in: "false" })
    }

    return (
        <div className="homeIcons">
            <UserDetails userStatus={userStatus} />
            <ul>
                <li><Link to="/weight">Add Weight</Link></li>
                <li><Link to="/activity">Add Activity</Link></li>
                <li><Link to="/graphs">Graphs</Link></li>
            </ul>
        </div>
    )
};
