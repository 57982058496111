import Section from "./Section";
import DisplayActivities from "../charts/DisplayActivities";
import DisplayWeights from "../charts/DisplayWeights";
import LoginHelpers from "../auth/LoginHelpers";

const Graphs = () => {

    if (LoginHelpers.IsTokenValid()) {
        LoginHelpers.RefreshToken();
    }

    return(
        <div>
            <DisplayActivities />
            <DisplayWeights />
        </div>

    );
};

export default Graphs;
